.App-link {
    color: dodgerblue;
}

.dark {
    background-color: #232323;
    color: #fff;
}

.light {
    background-color: #fff;
    color: #232323;
}

.App-header {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-header img {
    max-width: 500px;
    height: auto;
}

@media (max-width: 700px) {
    .App-header img {
        margin-top: 30px;
        max-width: 75%;
        height: auto;
    }
}

.dark .App-header img {
    filter: invert(1);
}

.login-page {
    background-color: transparent;
}

.dark .login-page {
    background-color: #232323;
}

.dark .login-page .paragraph {
    color: #FFFFFF;
}

.login-page .paragraph {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 25%;
    margin-right: 25%;
    text-align: center;
}

@media (max-width: 700px) {
    .login-page .paragraph {
        margin-left: 10px;
        margin-right: 10px;
    }
}


.login-page .btn {
    color: #191414 !important;
    margin: 0;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: #1db954 !important;
}

.login-page .btn:hover {
    background-color: #138039 !important;
    color: #FFFFFF !important;
}

.login-page .btn svg {
    margin-right: 5px;
    fill: #191414;
    transition: 0.3s;
}

.login-page .btn:hover svg {
    fill: #FFFFFF !important;
    transition: 0.3s;
}

.main-page .song-link-label {
    margin: 0;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    text-align: center;
}

.main-page .textbox {
    margin: 0;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
}

.main-page .textbox input {
    width: 50%;
    max-width: 600px;
}

@media (max-width: 700px) {
    .main-page .textbox {
        width: 90%;
    }
}

.main-page .buttons {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    display: inline-block;
    text-align: center;
}

.notification-container {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.notification {
    pointer-events: none;
    padding: 10px;
    border-radius: 10px;
    border: solid #1db954;
    z-index: 2;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    background: #e5e5e5;
}

.notification.show {
    opacity: 1;
}

.dark .notification {
    background: #191414;
}

.error-notification-container {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.error-notification {
    pointer-events: none;
    padding: 10px;
    border-radius: 10px;
    border: solid #c91212;
    z-index: 2;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    background: #e5e5e5;
}

.error-notification.show {
    opacity: 1;
}

.dark .error-notification {
    background: #191414;
}



.slidedown {
    margin-top: 20px;
    margin-left: 15%;
    margin-right: 15%;;
    margin-bottom: 25px;
}

.slidedown input {
    cursor: pointer;
}

.btn1 {
    background-color: #70369d;
    border-color: #70369d;
}

.btn1:hover {
    background-color: #532875;
    border-color: #532875;
}

.btn2 {
    background-color: #487de7;
    border-color: #487de7;
}

.btn2:hover {
    background-color: #2b4d8f;
    border-color: #2b4d8f;
}

.btn3 {
    background-color: #79c314;
    border-color: #79c314;
}

.btn3:hover {
    background-color: #4f7e0c;
    border-color: #4f7e0c;
}

.btn4 {
    background-color: #ffa500;
    border-color: #ffa500;
}

.btn4:hover {
    background-color: #ae7101;
    border-color: #ae7101;
}

.btn5 {
    background-color: #e81416;
    border-color: #e81416;
}

.btn5:hover {
    background-color: #8e0c0e;
    border-color: #8e0c0e;
}

@media (min-width: 700px) {
    .SlideDownMenu-content {
        display: flex;
        justify-content: center;
    }

    .SlideDownMenu-content .menu-item {
        position: relative;
        display: inline-grid;
        text-align: center;
        width: 175px;
        margin-inline: 25px;
        flex: 1 1 100px;
    }

    .SlideDownMenu-content .menu-item input[type="checkbox"] {
        display: block;
        margin: 0 auto;
    }
}

@media (max-width: 700px) {
    .SlideDownMenu-content {
        display: grid;
        align-items: center;
        text-align: center;
    }

    .menu-item {
        display: grid;
        align-items: center;
        justify-items: center;
        margin: 7px auto;
        width: 100%;
    }

    .menu-item input,
    .menu-item label {
        text-align: center;
        margin: 2px 0;
    }

    .menu-item input[type="range"] {
        width: 80%;
    }
}

.main-page .btn {
    margin: 10px;
}

.currently-playing {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
}

.song-grid {
    margin-top: 50px;
    padding-bottom: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

.song {
    position: relative;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 25px;
    background-color: #ececec;
}

.dark .song {
    background-color: #191414;
}

.song p {
    margin-bottom: 50px;
}

.main-page a {
    color: inherit;
    text-decoration: none;
}

.song img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    padding: 15px;
}

.song-info {
    margin-top: 10px;
}

.song-link {
    position: absolute;
    bottom: 25px;
    margin-top: 10px;
    color: #1db954 !important;
    text-decoration: none;
    font-size: 18px;
}

.song:hover .song-link {
    text-decoration: underline;
}

.song-link svg {
    fill: #1db954;
    margin-right: 7px;
}


/* DARK MODE TOGGLE */

.toggle-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.toggle-slot {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 2em;
    width: 4em;
    border-radius: 10em;
    background-color: #ececec;
    transition: background-color 250ms;
}

.toggle-checkbox:checked ~ .toggle-slot {
    background-color: #374151;
}

.toggle-button {
    transform: translate(2.35em, 0.35em);
    position: absolute;
    height: 1.3em;
    width: 1.3em;
    border-radius: 50%;
    background-color: #ffeccf;
    box-shadow: inset 0px 0px 0px 0.75em #ffbb52;
    transition: background-color 250ms, border-color 250ms, transform 500ms cubic-bezier(.26, 2, .46, .71);
}

.toggle-checkbox:checked ~ .toggle-slot .toggle-button {
    background-color: #485367;
    box-shadow: inset 0px 0px 0px 0.75em white;
    transform: translate(0.35em, 0.35em);
}

.sun-icon {
    position: absolute;
    height: 1.2em;
    width: 1.2em;
    color: #ffbb52;
}

.sun-icon-wrapper {
    position: absolute;
    height: 1.2em;
    width: 1.2em;
    opacity: 1;
    transform: translate(0.4em, 0.4em) rotate(15deg);
    transform-origin: 50% 50%;
    transition: opacity 150ms, transform 500ms cubic-bezier(.26, 2, .46, .71);
}

.toggle-checkbox:checked ~ .toggle-slot .sun-icon-wrapper {
    opacity: 0;
    transform: translate(0.6em, 0.4em) rotate(0deg);
}

.moon-icon {
    position: absolute;
    height: 1.2em;
    width: 1.2em;
    color: white;
}

.moon-icon-wrapper {
    position: absolute;
    height: 1.2em;
    width: 1.2em;
    opacity: 0;
    transform: translate(2.2em, 0.4em) rotate(0deg);
    transform-origin: 50% 50%;
    transition: opacity 150ms, transform 500ms cubic-bezier(.26, 2.5, .46, .71);
}

.toggle-checkbox:checked ~ .toggle-slot .moon-icon-wrapper {
    opacity: 1;
    transform: translate(2.4em, 0.4em) rotate(-15deg);
}
